<template>
  <div class="project-container">
    <LeftColumn :state="state" style="width: 260px; height: 100%">
      <template #buttonText> 新建手表项目 </template>
      <template #dataContent>
        <LeftList v-infinite-scroll="loadMore" infinite-scroll-disabled="disabled" @showDetail="showDetail" :dataList="projectList"></LeftList>
      </template>
      <template #dialog>
        <div slot="title" class="rttm-card-title" style="margin-top: 3px;margin-left: 8px">
          新建手表项目
        </div>
        <ProjectDialog @commitData="commitData">
        </ProjectDialog>
      </template>
    </LeftColumn>
    <div class="project-main-section">
      <MainSection v-if="projectList.length" :projectInfo="showProjectInfo" @updateList="refreshProjectList"></MainSection>
      <div v-else style="text-align: center;background-color: white;display: flex;flex-direction: column;height: 100%">
        <div style="flex: 1"></div>
        <div>
          <img src="https://modao.cc/mb-dashboard/images/empty-star.svg">
        </div>
        <div style="color: #8c939d;margin-top: 20px">
          暂无选择手表项目, 您可以点击左侧项目列表进行选择
        </div>
        <div style="flex: 1"></div>
      </div>
    </div>
  </div>
</template>

<script>
import LeftColumn from '@/components/layout/LeftColumn.vue'
import ProjectDialog from './ProjectDialog.vue'
import MainSection from './MainSection.vue'
import LeftList from '../components/LeftList.vue'
import SoftwareApi from '@/common/api/SoftwareApi'
import { mapState } from 'vuex'

export default {
  components: {
    LeftColumn,
    ProjectDialog,
    LeftList,
    MainSection
  },
  data () {
    return {
      state: this.$ez.model.viewState(0),
      projectList: [],
      showProjectInfo: {},
      pageSize: 10,
      curPageNo: 1,
      totalPages: 0,
      isLoading: false
    }
  },
  computed: {
    ...mapState('my', {
      uid: (state) => state.uid
    }),
    disabled () {
      return this.isLoading || !(this.curPageNo < this.totalPages)
    }
  },
  methods: {
    getProjectList () {
      return SoftwareApi.getProjectsByUid({ pageNo: this.curPageNo, pageSize: this.pageSize }).then((res) => {
        this.totalPages = res.data.pages
        this.isLoading = false
        this.state.toSuc()
        if (!res.data.records.length) {
          this.state.toNoData()
        }
        return res.data.records
      }).catch(err => {
        this.isLoading = false
        console.error(err)
        this.state.toFail()
      })
    },
    showDetail (index) {
      this.showProjectInfo = this.projectList[index] ?? {}
    },
    getIndexById (id) {
      return this.projectList.findIndex((item) => item.id === id)
    },
    commitData (data) {
      SoftwareApi.createProject(data).then(res => {
        this.$bus.$emit('hideSoftWareDialog')
        this.projectList = []
        this.refreshProjectList()
      })
    },
    // 初始化项目列表
    initProjectList () {
      this.getProjectList().then(res => {
        this.projectList = res
        if (this.projectList.length) {
          let index = 0
          if (this.$route.params?.id) {
            index = this.getIndexById(this.$route.params?.id)
          }
          this.showProjectInfo = this.projectList[index >= 0 ? index : 0]
        }
      })
    },
    loadMore () {
      if (this.curPageNo < this.totalPages) {
        this.curPageNo++
        this.isLoading = true
        this.getProjectList().then(res => {
          this.projectList.push(...res)
        })
      }
    },
    refreshProjectList () {
      const tmpNo = this.curPageNo
      const tmpSize = this.pageSize
      this.pageSize = this.pageSize * this.curPageNo
      this.pageNo = 1
      this.getProjectList().then(res => {
        this.projectList = res
        this.pageNo = tmpNo
        this.pageSize = tmpSize
        if (this.projectList.length === 1) {
          this.showProjectInfo = this.projectList[0]
        }
      })
    }
  },
  created () {
    this.initProjectList()
  }
}
</script>

<style lang="less" scoped>
.project-container{
  display: flex;
  height: 100%;
  .project-main-section{
    flex-grow: 1;
  }
}
</style>
