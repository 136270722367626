<template>
  <div class="create-project-dialog">
    <div class="project-edit-section">
      <el-form ref="projectForm" :rules="rules" :model="project" label-width="120px">
        <el-form-item label="设备型号" prop="name">
          <el-input v-model="project.name" placeholder="请输入设备型号"></el-input>
        </el-form-item>
        <el-form-item  label="产品分类" prop="category">
          <el-select v-model="project.category">
            <el-option v-for="(item, index) in projectClassList" :key="index" :label="item.categoryName" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="主控芯片" prop="chip">
          <el-input v-model="project.chip" placeholder="请输入芯片型号"></el-input>
        </el-form-item>

        <el-form-item label-width="0" :model="project">
          <el-form :inline="true" label-width="120px">
            <el-form-item label="系统内存">
              <el-input-number size="medium" :min="1" v-model="project.memorySize"></el-input-number> MB
            </el-form-item>
            <el-form-item label="存储空间">
              <el-input-number size="medium" :min="1" v-model="project.storageSize"></el-input-number> GB
            </el-form-item>
            <el-form-item label="屏幕宽度">
              <el-input-number size="medium" :min="1" v-model="project.screenWidth"></el-input-number>
            </el-form-item>
            <el-form-item label="屏幕高度">
              <el-input-number size="medium" :min="1" v-model="project.screenHeight"></el-input-number>
            </el-form-item>
          </el-form>
        </el-form-item>
        <el-form-item label="屏幕外观">
          <el-radio-group v-model="project.screenType">
            <el-radio :label="1">圆屏</el-radio>
            <el-radio :label="0">方屏</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="支持 WIFI">
          <el-radio-group v-model="project.wifi">
            <el-radio :label="1">是</el-radio>
            <el-radio :label="0">否</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="支持 NFC">
          <el-radio-group v-model="project.nfc">
            <el-radio :label="1">是</el-radio>
            <el-radio :label="0">否</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="支持 esim">
          <el-radio-group v-model="project.esim">
            <el-radio :label="1">是</el-radio>
            <el-radio :label="0">否</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="支持 GPS">
          <el-radio-group v-model="project.gps">
            <el-radio :label="1">是</el-radio>
            <el-radio :label="0">否</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="蓝牙类型">
          <el-radio-group v-model="project.bleType">
            <el-radio :label="1">低功耗蓝牙</el-radio>
            <el-radio :label="0">双模蓝牙</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
      <div class="project-logo">
        <span>项目 logo</span>
        <img :src="project.logo" style="height: 80px;width: 80px;;border-radius: 10px;border: 1px #eaebee solid">
        <div style="margin-top: 10px;display:flex;">
          <EzFileSel @afterUpload="afterUploadLogo" style="height: 32px">
            <el-button type="primary" size="small" style="background-color: #0052D9;border: 0px;height: 32px;width: 80px;text-align: center;padding: 0px" >切换logo</el-button>
          </EzFileSel>
        </div>
      </div>
    </div>
    <div class="project-submit">
      <div style="text-align: center;margin-top: 20px">
        <el-button type="primary" style="background-color: #0052D9;border: 0px;height: 38px" @click="commitData">
          <slot name="operationText">提交项目</slot>
        </el-button>
      </div>
    </div>
  </div>
</template>

<script>
import SoftwareApi from '@/common/api/SoftwareApi'
export default {
  name: 'ProjectDialog',
  props: {
    projectInfo: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      project: {
        name: '',
        category: null,
        chip: '',
        memorySize: 64,
        storageSize: 2,
        screenWidth: 360,
        screenHeight: 360,
        screenType: 1,
        wifi: 0,
        nfc: 0,
        esim: 0,
        gps: 0,
        bleType: 1,
        logo: 'https://rttoss.oss-cn-shanghai.aliyuncs.com/img/def_application_logo.png'
      },
      projectClassList: [],
      rules: {
        category: [{ required: true, message: '请选择产品分类' }],
        name: [{ required: true, message: '请输入产品型号', trigger: 'blur' }],
        chip: [{ required: true, message: '请输入主控芯片型号', trigger: 'blur' }]
      }
    }
  },
  methods: {
    afterUploadLogo (data) {
      this.project.logo = data[0].url
    },
    commitData () {
      this.$refs.projectForm.validate((valid) => {
        if (valid) {
          this.$emit('commitData', this.project)
          return true
        } else {
          this.$message.error('信息填写不完整')
          return false
        }
      })
    },
    getCategory () {
      SoftwareApi.getProjectCategory().then(res => {
        if (res.code === 0) {
          this.projectClassList = res.data
        }
      })
    }
  },
  created () {
    this.getCategory()
    if (this.projectInfo) {
      this.project = JSON.parse(JSON.stringify(this.projectInfo))
    }
  }
}
</script>

<style lang="less" scoped>
.create-project-dialog{
  margin: 10px 0;
  max-height: calc(100vh - 200px);
  overflow: auto;
  padding: 20px 0;
  box-sizing: border-box;
  box-shadow: inset 1px 2px #eef0f3;
  .project-edit-section{
    display: flex;
    width: 100%;
    flex-direction: row;
    .project-logo{
      margin-left: 50px;
      margin-right: 50px;
      width: 300px;
      span {
        display: inline-block;
        padding: 8px;
      }
    }
  }
}
</style>
