<template>
  <div style="">
    <div class="rttm-card" style="margin: 16px;">
      <!-- 顶部基本信息 -->
      <div>
        <div style="display: flex">
          <div style="height: 80px;width: 80px">
            <img :src="projectInfo.logo" style="height: 100%;width: 100%;border: 1px #f3f4f6 solid;border-radius: 15px;object-fit: cover">
          </div>
          <div style="margin-left: 20px;flex: 1;">
            <div style="display: flex">
              <div style="font-size: 22px;font-weight: 900">{{projectInfo.name}}</div>
            </div>
            <div style="display: flex;margin-top: 5px; flex-direction: column">
              <div style="font-size: 13px;color: #b9bfc7;margin-right: 10px">
                <span>项目 ID</span>
                <span style="color: #0052D9">
                  {{ projectInfo.id }}
                </span>
              </div>
              <div style="font-size: 13px;color: #b9bfc7;margin-right: 10px; margin-top: 10px">
                <span style="margin-right: 10px">产品分类</span>
                <el-tag size="mini">{{projectInfo.categoryName}}</el-tag>
              </div>
            </div>
          </div>
          <div style="">
            <el-button @click="editDialog = true" type="primary" size="mini" round style="background-color: #0052d9;border: 0px;height: 32px">编辑信息</el-button>
            <el-dialog title="更新项目信息" top="60px" width="800px" :destroy-on-close="true" :visible.sync="editDialog">
              <div v-if="editDialog">
                <ProjectDialog @commitData="commitData" :projectInfo="projectInfo">
                  <template #operationText>更新项目</template>
                </ProjectDialog>
              </div>
            </el-dialog>
          </div>
        </div>
      </div>
      <div class="project-info-list">
        <div class="info-item">
          <div class="info-title">主控芯片</div>
          <div class="info-val" :title="projectInfo.chip">{{projectInfo.chip}}</div>
        </div>
        <div class="info-item">
          <div class="info-title">系统内存</div>
          <div class="info-val">{{projectInfo.memorySize}}</div>
          <div class="info-unit">MB</div>
        </div>
        <div class="info-item">
          <div class="info-title">存储空间</div>
          <div class="info-val">{{projectInfo.storageSize}}</div>
          <div class="info-unit">GB</div>
        </div>
        <div class="info-item">
          <div class="info-title">屏幕外观</div>
          <div class="info-val">{{projectInfo.screenType === 1 ? '圆屏' : '方屏'}}</div>
        </div>
        <div class="info-item">
          <div class="info-title">分辨率</div>
          <div class="info-val">{{`${projectInfo.screenWidth} * ${projectInfo.screenHeight}`}}</div>
        </div>
        <div class="info-item">
          <div class="info-title">WIFI</div>
          <div class="info-val">{{projectInfo.wifi ? '支持' : '不支持'}}</div>
        </div>
        <div class="info-item">
          <div class="info-title">ESIM</div>
          <div class="info-val">{{projectInfo.esim ? '支持' : '不支持'}}</div>
        </div>
        <div class="info-item">
          <div class="info-title">蓝牙类型</div>
          <div class="info-val">{{projectInfo.bleType === 0 ? '双模蓝牙' : '低功耗蓝牙'}}</div>
        </div>
        <div class="info-item">
          <div class="info-title">NFC</div>
          <div class="info-val">{{projectInfo.nfc ? '支持' : '不支持'}}</div>
        </div>
        <div class="info-item">
          <div class="info-title">GPS</div>
          <div class="info-val">{{projectInfo.gps ? '支持' : '不支持'}}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SoftwareApi from '@/common/api/SoftwareApi'
import ProjectDialog from './ProjectDialog.vue'
export default {
  name: 'SoftwareDetail',
  props: {
    projectInfo: {
      type: Object,
      default: () => {}
    }
  },
  components: {
    ProjectDialog
  },
  data () {
    return {
      editDialog: false
    }
  },
  methods: {
    copy (data) {
      const url = data
      const oInput = document.createElement('input')
      oInput.value = url
      document.body.appendChild(oInput)
      oInput.select() // 选择对象;
      document.execCommand('Copy') // 执行浏览器复制命令
      this.$message({
        message: '复制成功',
        type: 'success'
      })
      oInput.remove()
    },
    commitData (data) {
      SoftwareApi.updateProject(data).then(res => {
        this.editDialog = false
        this.$emit('updateList')
      })
    }
  }
}
</script>
<style scoped lang="less">
  .project-info-list{
    margin-top: 80px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    border-top: 1px solid #8080807b;
    .info-item{
      flex-basis: 20%;
      height: 120px;
      flex-grow: 0;
      flex-shrink: 1;
      text-align: center;
      overflow: hidden;
      .info-title,.info-unit{
        margin: 10px 0;
        font-size: 13px;
        color: #8080807b;
      }
      .info-val{
        width: 100%;
        font-size: 18px;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

    }
  }
</style>
